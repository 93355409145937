const myMixin = {
  data() {
    return {
      user_data: this.$store.getters['auth/getDataProfile'],
    };
  },
  methods: {
    getImage(id = 0) {
      return this.axios
        .get(this.api.getMediaUrl + id)
        .then((res) => {
          return res.data.media_details.sizes;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    /**
     *
     * @param image {name, orig}
     */
    sendImage(image) {
      let formData = new FormData();
      const name = image.name;

      formData.append('name', image.name);
      formData.append('file', image);

      const token = this.$store.getters['auth/getToken'];

      let config = {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'filename=' + name,
        },
      };
      return this.axios.post(this.api.sendMedia, formData, config);
    },
    async bookmark() {
      if (!this.$store.getters['auth/isLoggedIn']) {
        alert(
          "E' necessario effettuare il login per salvare questa ricetta nei preferiti",
        );
        return;
      }

      this.loading = true;

      const params = {
        id: this.id,
        type: 'ricetta',
      };

      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };

      let send = {};
      let send_type = '';
      if (this.bookmarked()) {
        send = this.axios.delete(
          this.api.bookmark + '?id=' + this.id + '&type=ricetta',
          config,
        );
        send_type = 'delete';
      } else {
        send = this.axios.post(this.api.bookmark, params, config);
        send_type = 'add';
      }
      const r = {
        nr: 0,
      };
      return send
        .then((response) => {
          this.loading = false;
          r.nr = response.data.nr;

          switch (send_type) {
            case 'add':
              if (typeof this.user_data.bookmark.ricetta == 'undefined') {
                this.user_data.bookmark.ricetta = [];
              }
              this.user_data.bookmark.ricetta.push(this.id);
              this.$store.commit('auth/setDataProfile', this.user_data);
              break;

            case 'delete':
              this.user_data.bookmark.ricetta =
                this.user_data.bookmark.ricetta.filter((item) => {
                  return parseInt(item) !== this.id;
                });
              this.$store.commit('auth/setDataProfile', this.user_data);
              break;
          }
          return r;
        })
        .catch((error) => {
          alert(
            'Operazione al momento non possibile. Si prega di provare tra qualche minuto',
          );
          this.loading = false;
          console.log(error);
        });
    },
    async like() {
      console.log("like2");
      if (!this.$store.getters['auth/isLoggedIn']) {
        alert("E' necessario effettuare il login per votare questa ricetta");
        return;
      }

      this.loading = true;

      const type = 'ricetta';
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };

      const params = {
        id: this.id,
        type,
      };

      let trovato = this.liked2();
      let send = {};
      let type_send = '';

      if (trovato) {
        send = this.axios.delete(
          this.api.like + '?id=' + this.id + '&type=ricetta',
          config,
        );
        type_send = 'delete';
      } else {
        send = this.axios.post(this.api.like, params, config);
        type_send = 'add';
      }

      const r = {
        nr: 0,
      };
      return send
        .then(async (response) => {
          r.nr = response.data.nr;

          this.loading = false;
          switch (type_send) {
            case 'add':
              const r2 = await this.user_data.like.ricetta.push(this.id);
              break;

            case 'delete':
              this.user_data.like.ricetta = this.user_data.like.ricetta.filter(
                (item) => {
                  return parseInt(item) !== this.id;
                },
              );
              break;
          }
          this.$store.commit('auth/setDataProfile', this.user_data);
          return r;
        })
        .catch((error) => {
          this.loading = false;
          alert(
            'Operazione al momento non possibile. Si prega di provare tra qualche minuto',
          );
          console.log(error);
        });
    },
    bookmarked() {
      let trovato = -1;

      console.log("Cerco "+this.id+ " in");
      console.log(this.user_data.bookmark.ricetta);
      if (!this.$store.getters['auth/isLoggedIn']) return false;

      if (typeof this.user_data.bookmark.ricetta == 'undefined') return false;

      this.user_data.bookmark.ricetta.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });

      console.log("trovato: "+trovato);
      if (trovato > -1) return true;
      return false;
    },
    liked2() {
      let trovato = -1;

      if (!this.$store.getters['auth/isLoggedIn']) return false;

      this.user_data.like.ricetta.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });

      if (trovato > -1) return true;
      return false;
    },
  },

  computed: {
    liked() {
      let trovato = -1;

      if (!this.$store.getters['auth/isLoggedIn']) return false;

      this.user_data.like.ricetta.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });

      if (trovato > -1) return true;
      return false;
    },
  },
};

export default myMixin;
