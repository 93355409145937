<template>
  <div id="intro">
    <div class="avatar">
      <img v-if="dati.avatar != false" :src="avatar" />
      <img
        v-if="dati.avatar == false"
        src="../../assets/icons/user-white.svg"
      />
    </div>
    <div class="dati">
      <div>
        <div class="name">{{ dati.first_name }} {{ dati.last_name }}</div>
        <div class="bio">{{ dati.bio }}</div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div v-if="listing.length == 0">
    <h2>{{ $gettext('Al momento non hai inserito nessuna ricetta.') }}</h2>
  </div>
  <div id="recipe" v-if="listing.length > 0">
    <recipe-card
      v-for="item in listing"
      :key="item.id"
      :title="item.title"
      :image="item.image"
      :id="item.id"
      :item="item"
    ></recipe-card>
  </div>
  <div class="clearfix"></div>
  <hr />
  <div class="bottoni">
    <div id="logout">
      <button @click="cambioPassword">{{ $gettext('Cambia password') }}</button>
    </div>
    <div id="logout">
      <button @click="cambioProfilo">{{ $gettext('Cambia dati profilo') }}</button>
    </div>
  </div>
  <div id="logout">
    <button @click="logout">Logout</button>
  </div>
  <div id="spaziovuoto"></div>
</template>

<script>
import recipeCard from '@/components/recipe/RecipeCard';

export default {
  name: 'profile',
  components: { recipeCard },
  data() {
    return {
      dati: '',
      avatar: '',
      bio: '',
      id: 0,
      nicename: '',
      name: '',
      surname: '',
      listing: [],
    };
  },
  created() {
    const data = this.$store.getters['auth/getDataProfile'];

    //console.log(data);

    this.dati = data;
    this.avatar = data.avatar;
    this.id = data.id;
    this.bio = data.bio;
    this.nicename = data.nicename;
    this.getRecipe();
  },
  methods: {
    logout() {
      this.$store.commit('auth/setLogout');
      this.$router.replace('/');
    },
    cambioPassword() {
      this.$router.push({ name: 'Cambio password' });
    },
    async loadImageURL(idM, idListing) {
      let mediaUrl = this.api.getMediaUrl + idM;

      await fetch(mediaUrl)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Error in loading image');
          }
        })
        .then((res) => {
          this.listing[idListing].image =
            res.media_details.sizes.medium_large.source_url;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
    async getRecipe() {
      try {
        let response = await this.axios.get(
          this.api.getRecipeAuthor + this.dati.id,
        );
        const listing = [];

        response.data.forEach((item, index) => {
          listing.push({
            authorName: item.author_name,
            authorId: item.author,
            id: item.id,
            title: item.title.rendered,
            image: '/images/placeholder-768x768.png',
            categoria: '',
            item: item,
          });

          this.loadImageURL(item.featured_media, index);
        });

        this.listing = listing;
      } catch (error) {}
    },
    cambioProfilo() {
      this.$router.push({ name: 'Profile change data' });
    },
  },
};
</script>

<style scoped>
#intro {
  margin-bottom: 30px;
  padding: 20px;
  background-color: var(--var-primary-color);
}

#intro .avatar {
  width: 20%;
  float: left;
  margin-right: 20px;
}

#intro .avatar img {
  width: 50px;
  height: 50px;
  border-radius: 26px;
}

#intro {
  display: flex;
  align-items: center;
  color: white;
}

#intro .dati {
  width: 100%;
  text-align: left;
}

.bottoni a {
  color: white;
  text-decoration: none;
}

#intro .name {
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;
}

.bottoni {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottoni > div {
  width: auto;
  padding: 5px;
  float: left;
  min-width: 100px;
  color: white;
  font-weight: 600;
  text-align: center;
}

.bottoni > div:nth-child(odd) {
  margin-right: 20px;
}

.bottoni.disable .segui {
  opacity: 0.8;
}

.image {
  height: 200px;
  max-height: 200px;
}

#spaziovuoto {
  height: 10px;
}

#recipe {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 0 auto;
}
</style>
